import * as ContextMenu from '@radix-ui/react-context-menu'
import {Link} from '@remix-run/react'

export interface LogoDropdownProps {
  children: React.ReactNode
  hasActiveSession: boolean
}

export function LogoDropdown({children, hasActiveSession}: LogoDropdownProps) {
  return (
    <ContextMenu.Root>
      <ContextMenu.Trigger>{children}</ContextMenu.Trigger>
      <ContextMenu.Portal>
        <ContextMenu.Content className="z-30 rounded-lg border border-radix-mauve7 bg-radix-mauve3/70 p-1 backdrop-blur">
          <ContextMenu.Item
            asChild
            className="flex w-48 justify-between rounded border border-transparent px-2 py-1 text-sm font-medium leading-none outline-none hover:bg-radix-blue8"
          >
            <Link to={hasActiveSession ? '/home' : '/'}>Home</Link>
          </ContextMenu.Item>
          <ContextMenu.Item
            asChild
            className="flex w-48 justify-between rounded border border-transparent px-2 py-1 text-sm font-medium leading-none outline-none hover:bg-radix-blue8"
          >
            <Link to="/blog">Blog</Link>
          </ContextMenu.Item>
          <ContextMenu.Item
            asChild
            className="flex w-48 justify-between rounded border border-transparent px-2 py-1 text-sm font-medium leading-none outline-none hover:bg-radix-blue8"
          >
            <Link to="/docs">Documentation</Link>
          </ContextMenu.Item>
          <ContextMenu.Separator className="m-1 h-px bg-radix-mauve6" />
          <ContextMenu.Item
            asChild
            className="flex w-48 justify-between rounded border border-transparent px-2 py-1 text-sm font-medium leading-none outline-none hover:bg-radix-blue8"
          >
            <Link to="/about/brand-assets">Brand assets</Link>
          </ContextMenu.Item>
        </ContextMenu.Content>
      </ContextMenu.Portal>
    </ContextMenu.Root>
  )
}
