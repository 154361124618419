import {cx} from 'class-variance-authority'
import {useStatus} from '~/hooks/useStatus'

export const SystemStatusIcon = () => {
  const status = useStatus()

  if (!status) return <div className="inline-block h-2 w-2" />

  return (
    <div
      className={cx(
        'inline-block h-2 w-2 rounded-full',
        status.page.status === 'UP'
          ? 'bg-radix-grass9'
          : status.page.status === 'UNDERMAINTENANCE'
            ? 'bg-radix-amber9'
            : 'bg-radix-red9',
      )}
    >
      <span className="sr-only"></span>
    </div>
  )
}
