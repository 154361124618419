import {Link} from '@remix-run/react'
import {useDiscordOnlineCount} from '~/hooks/useDiscordOnlineCount'
import {useParentLoaderData} from '~/hooks/useParentLoaderData'
import {SystemStatusIcon} from './SystemStatusIcon'
import {DiscordIcon, GitHubLogoIcon, LinkedInLogoIcon, TwiterLogoIcon} from './icons'
import {DepotIcon, DepotLogo} from './logo'
import {VantaLogo, YCombinatorLogo} from './logos'

export interface GlobalFooterProps {
  mode: 'app' | 'docs' | 'marketing'
}

export function GlobalFooter({mode}: GlobalFooterProps) {
  const discordOnlineCount = useDiscordOnlineCount()

  const rootData = useParentLoaderData('root')
  const hasActiveSession = Boolean(rootData?.email)

  if (mode === 'app') {
    return (
      <footer className="mt-auto w-full border-t border-radix-mauve12 border-opacity-10 pb-8">
        <div className="flex w-full justify-center pt-8">
          <div className="flex space-x-4 text-sm text-radix-mauve10">
            <Link
              to={hasActiveSession ? '/home' : '/'}
              className="flex items-center hover:text-radix-mauve12"
              prefetch="intent"
            >
              <DepotIcon className="mr-2 inline-block h-4 w-4" />
              <span>Home</span>
            </Link>
            <Link to="/docs" className="hover:text-radix-mauve12" prefetch="intent">
              Docs
            </Link>
            <Link to="/help" className="hover:text-radix-mauve12" prefetch="intent">
              Help
            </Link>
            <a href="https://status.depot.dev" className="hover:text-radix-mauve12" target="_blank" rel="noreferrer">
              System Status <SystemStatusIcon />
            </a>
          </div>
        </div>
      </footer>
    )
  }

  return (
    <footer className="mt-auto w-full border-t border-radix-mauve12 border-opacity-10 pb-8 text-radix-mauve10 md:text-sm">
      <div className="mx-auto flex max-w-7xl flex-col justify-between gap-4 px-4 py-8 sm:items-center sm:px-0 md:flex-row">
        <div className="flex flex-col items-start justify-center gap-2">
          <Link
            to={hasActiveSession ? '/home' : '/'}
            className="flex-1 text-lg font-bold text-radix-mauve12"
            prefetch="intent"
          >
            <DepotLogo className="h-8" />
            <span className="sr-only">Depot</span>
          </Link>

          <a
            href="https://www.ycombinator.com/companies/depot"
            className="mt-2 flex items-center justify-center gap-2 text-radix-mauve11"
            target="_blank"
            rel="noreferrer"
          >
            <div>Backed by</div>
            <YCombinatorLogo className="h-4" />
            <span className="sr-only">Y Combinator</span>
          </a>

          <VantaLogo className="mt-4 h-16 text-radix-mauve5" />
        </div>

        <div className="grid grid-cols-1 gap-8 sm:grid-cols-3 sm:justify-end">
          <div className="space-y-2">
            <div className="text-xs font-bold uppercase text-radix-mauve10">Product</div>
            <div>
              <Link to="/pricing" className="hover:text-radix-mauve12" prefetch="intent">
                Pricing
              </Link>
            </div>
            <div>
              <Link to="/docs/security" className="hover:text-radix-mauve12" prefetch="intent">
                Security
              </Link>
            </div>
            <div>
              <Link to="/dockerfile-explorer" className="hover:text-radix-mauve12" prefetch="intent">
                Dockerfile Explorer
              </Link>
            </div>
            <div>
              <Link to="/drop-week" className="hover:text-radix-mauve12" prefetch="intent">
                Drop Week
              </Link>
            </div>
            <div>
              <a
                href="https://depot.ai"
                className="bg-gradient-to-br from-radix-crimson11 to-radix-blue11 bg-clip-text text-transparent hover:text-radix-mauve12"
              >
                depot.ai
              </a>
            </div>
            <div>
              <a href="/github-actions-price-calculator" className="hover:text-radix-mauve12">
                GitHub Actions Price Calculator
              </a>
            </div>
          </div>

          <div className="space-y-2">
            <div className="text-xs font-bold uppercase text-radix-mauve10">Support</div>
            <div>
              <Link to="/docs" className="hover:text-radix-mauve12" prefetch="intent">
                Documentation
              </Link>
            </div>
            <div>
              <Link to="/help" className="hover:text-radix-mauve12" prefetch="intent">
                Support
              </Link>
            </div>
            <div>
              <a
                href="https://discord.gg/MMPqYSgDCg"
                className="hover:text-radix-mauve12"
                target="_blank"
                rel="noreferrer"
              >
                Discord{' '}
                {discordOnlineCount > 0 && (
                  <span className="rounded border border-radix-violet6 bg-radix-violet4 px-1 text-xs text-radix-violet11">
                    {discordOnlineCount} online
                  </span>
                )}
              </a>
            </div>
            <div>
              <a
                href="https://status.depot.dev"
                className="inline-flex items-center leading-none hover:text-radix-mauve12"
                target="_blank"
                rel="noreferrer"
              >
                <span className="mr-1">System Status</span> <SystemStatusIcon />
              </a>
            </div>
          </div>

          <div className="space-y-2">
            <div className="text-xs font-bold uppercase text-radix-mauve10">Company</div>
            <div>
              <Link to="/about" className="hover:text-radix-mauve12" prefetch="intent">
                About
              </Link>
            </div>
            <div>
              <Link to="/blog" className="hover:text-radix-mauve12" prefetch="intent">
                Blog
              </Link>
            </div>
            <div>
              <Link to="/changelog" className="hover:text-radix-mauve12" prefetch="intent">
                Changelog{' '}
                <div className="inline-block h-2 w-2 rounded-full border border-radix-violet10 bg-radix-violet8">
                  <span className="sr-only"></span>
                </div>
              </Link>
            </div>
            <div>
              <a href="mailto:contact@depot.dev" className="hover:text-radix-mauve12">
                Contact Us
              </a>
            </div>
            <div>
              <Link to="/about/brand-assets" className="hover:text-radix-mauve12" prefetch="intent">
                Brand Assets
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="border-t border-radix-mauve12 border-opacity-10 px-4 pt-6 sm:px-0 md:px-8">
        <div className="mx-auto flex max-w-7xl items-center justify-between">
          <div className="flex flex-col gap-2 md:flex-row md:items-center md:gap-4">
            <div className="text-sm">&copy; {new Date().getFullYear()} Depot Technologies Inc.</div>
            <Link to="/terms-of-service" className="hover:text-radix-mauve12" prefetch="intent">
              Terms of Service
            </Link>
            <Link to="/privacy-policy" className="hover:text-radix-mauve12" prefetch="intent">
              Privacy Policy
            </Link>
            <Link to="/cookies" className="hover:text-radix-mauve12" prefetch="intent">
              Cookies
            </Link>
          </div>

          <div className="flex items-center space-x-4 text-xl">
            <a href="https://github.com/depot" className="hover:text-radix-mauve12" target="_blank" rel="noreferrer">
              <GitHubLogoIcon />
              <span className="sr-only">Depot GitHub account</span>
            </a>
            <a
              href="https://discord.gg/MMPqYSgDCg"
              className="hover:text-radix-mauve12"
              target="_blank"
              rel="noreferrer"
            >
              <DiscordIcon />
              <span className="sr-only">Depot Discord community</span>
            </a>
            <a
              href="https://twitter.com/depotdev"
              className="hover:text-radix-mauve12"
              target="_blank"
              rel="noreferrer"
            >
              <TwiterLogoIcon />
              <span className="sr-only">Depot Twitter account</span>
            </a>
            <a
              href="https://www.linkedin.com/company/depot-technologies"
              className="hover:text-radix-mauve12"
              target="_blank"
              rel="noreferrer"
            >
              <LinkedInLogoIcon />
              <span className="sr-only">Depot LinkedIn account</span>
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}
